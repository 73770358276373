<template>
  <div
    v-show="!gameplan_store.editMode"
    id="dropdown-input"
    ref="game-plan-dropdown"
    class="bg-white px-3 py-1 shadow-md w-72 outline-1 @apply text-gray-500 h-[32px] overflow-visible"
    :class="{
      'rounded-t': dropdownOpen,
      rounded: !dropdownOpen,
    }"
  >
    <div
      class="flex justify-between cursor-pointer"
      @click="() => toggleGameplanDropdown()"
    >
      <div>
        {{ gameplan_store.getCurrentGameplan }}
      </div>
      <div>
        <font-awesome-icon
          :icon="dropdownOpen ? faChevronUp : faChevronDown"
          class="text-primary"
        />
      </div>
    </div>
  </div>
  <Transition>
    <div
      v-if="dropdownOpen"
      id="dropDown"
      class="absolute dropdown text-gray-500"
    >
      <div class="relative max-w-sm px-3 py-2">
        <div class="relative max-w-sm">
          <GamePlanDatePicker
            key="SearchGamePlans"
            :model-value="dateValue ?? ''"
            :max-date="formatDate(new Date())"
            placeholder="Search game plans"
            :can-back-search="true"
            unique-id-string="SearchGamePlans"
            @update="
              event => {
                dateValue = event;
              }
            "
          />
        </div>
      </div>
      <div
        class="max-h-64 scrollbar-thumb-primary scrollbar-track-transparent scrollbar-thumb-rounded-xl scrollbar-track-rounded-xl scrollbar-thin mr-3"
      >
        <template
          v-for="[date, gameplans] in Object.entries(
            gameplan_store.getGameplans
          )"
          :key="date"
        >
          <div
            class="flex items-center gap-2 px-3 py-1 cursor-pointer"
            @click="
              () => {
                if (openExecutionId == gameplans[0].execution_id)
                  openExecutionId = null;
                else openExecutionId = gameplans[0].execution_id;
              }
            "
          >
            <font-awesome-icon
              :icon="
                gameplans.some(el => el.execution_id == openExecutionId)
                  ? faChevronDown
                  : faChevronRight
              "
              class="text-primary w-5 self-start pt-1"
            />
            <div class="flex flex-col w-full">
              <div
                class="hover:font-bold"
                :class="{
                  'font-bold': openExecutionId == gameplans[0].execution_id,
                }"
              >
                {{ formatDateRelative(gameplans[0].execution_date) }}
              </div>
              <div
                v-if="gameplans.some(el => el.execution_id == openExecutionId)"
              >
                <template
                  v-for="gameplan in gameplans"
                  :key="gameplan.execution_id"
                >
                  <div
                    class="hover:font-bold px-3 pt-1"
                    :class="{
                      'font-bold':
                        gameplan.execution_id ==
                        gameplan_store.selectedStrategyExecution?.execution_id,
                    }"
                    @click="
                      () => {
                        gameplan_store.selectStrategy(gameplan);
                        gameplan_store.getWorkLists(
                          DEFAULT_STRATEGY_ID,
                          gameplan_store.selectedStrategyExecution.execution_id
                        );
                        toggleGameplanDropdown();
                      }
                    "
                  >
                    {{ formatDate(gameplan.execution_date, 'h:mm a', false) }}
                  </div>
                </template>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
  </Transition>
</template>

<script setup>
import { onMounted, ref, watch } from 'vue';
import { useGamePlanStore, DEFAULT_STRATEGY_ID } from '@/stores/useGamePlans';
import {
  faChevronDown,
  faChevronUp,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons';
import { isToday, isValid, isYesterday } from 'date-fns';
import { formatDate } from '@/utils/helpers';
import GamePlanDatePicker from './GamePlanDatePicker.vue';

const gameplan_store = useGamePlanStore();

const dropdownOpen = ref(false);
const openExecutionId = ref(null);
const dateValue = ref(null);
const searchedDate = ref(null);

document.addEventListener('click', function (event) {
  const dropdownInput = document.getElementById('dropdown-input');
  const dropDown = document.getElementById('dropDown');
  const datePicker = document.querySelector('.datepicker');

  const elementsToWatch = [dropdownInput, dropDown, datePicker].filter(e => e);

  let isClickInside = Array.from(elementsToWatch).some(el =>
    el?.contains(event.target)
  );

  if (!isClickInside) {
    if (dropdownOpen.value) toggleGameplanDropdown();
  }
});

watch(dateValue, () => {
  if (!dateValue.value) {
    gameplan_store.resetWorklists();
    return;
  }
  const date = new Date(dateValue.value);
  gameplan_store.on = dateValue.value;

  if (isValid(date)) {
    const formattedDate = formatDate(date, 'yyyy-MM-dd');

    if (formattedDate == searchedDate.value) return;

    gameplan_store
      .getWorkStrategy(DEFAULT_STRATEGY_ID, formattedDate)
      .then(response => {
        if (response.success) {
          searchedDate.value = formattedDate;
          openExecutionId.value =
            gameplan_store.getGameplans?.[formattedDate]?.[0]?.execution_id;
        }
      });
  } else {
    if (searchedDate.value == null) return;

    gameplan_store.getWorkStrategy(DEFAULT_STRATEGY_ID).then(response => {
      if (response.success) {
        searchedDate.value = null;
        openExecutionId.value =
          gameplan_store.selectedStrategyExecution.execution_id;
      }
    });
  }
});

const toggleGameplanDropdown = () => {
  dropdownOpen.value = !dropdownOpen.value;
  openExecutionId.value = gameplan_store.selectedStrategyExecution.execution_id;
};

onMounted(async () => {
  if (gameplan_store.worklists?.length !== 0) return;
  gameplan_store.resetWorklists().then(() => {
    searchedDate.value = null;
  });
});

function formatDateRelative(date) {
  let localDate = new Date(date);
  if (isToday(localDate)) {
    return 'Today';
  } else if (isYesterday(localDate)) {
    return 'Yesterday';
  } else {
    return formatDate(localDate, 'MM/dd/yyyy', false);
  }
}
</script>

<style scoped>
.dropdown {
  @apply top-[11em] z-20 bg-white  shadow-md rounded-b w-72;
}
</style>
